<template>
  <router-view />
</template>

<script>
export default {
  name: "billing-index",
};
</script>

<style>
</style>